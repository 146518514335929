@import "../../scss/base.scss";

.text {
  margin: 0;
  font-weight: 400;
  color: $baseBlack;
}

.m {
  font-size: 14px;
  line-height: 18px;
  @include mobile {
    font-size: 12px;
    line-height: 15px;
  }
}

.regular {
  font-family: Circular Std Book;
}

.bold {
  font-family: Circular Std Bld;
}
