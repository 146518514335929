@import '../../scss/base.scss';

.reviews {
  display: flex;
  flex-direction: column;
  margin: 48px 0;
  gap: 48px;
  @include mobile {
    margin: 24px 0;
    gap: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h2,
      p {
        text-align: center;
      }
    }
  }

  .rating {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    cursor: pointer;
    @include mobile {
      gap: 12px;

      p {
        display: none;
      }
    }

    .image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .stars {
      background-image: url('/truspilot/five-starts-truspilot.svg');
      width: 150px;
      height: 30px;
      @include mobile {
        width: 115px;
        height: 25px;
      }
    }

    .logo {
      background-image: url('/truspilot/logo.svg');
      width: 100px;
      height: 30px;
      @include mobile {
        width: 75px;
        height: 20px;
      }
    }
  }

  .cards {
    box-sizing: border-box;
    width: 100%;
    max-width: 1058px;
    padding: 0 25px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;

    .slide {
      width: fit-content !important;

      .card {
        box-sizing: border-box;
        width: 320px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        border: 1px solid #d9d9d9;

        img {
          border-radius: 8px 8px 0 0;
        }

        .content {
          background-color: #fffaf3;
          border-radius: 0 0 8px 8px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .stars {
            display: flex;
            align-items: center;
            gap: 2px;
          }

          .review {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .link {
            text-decoration: none;
            color: $baseBlack;
            border-bottom: 1px solid $baseBlack;
          }
        }
      }
    }

    .swiperButton {
      box-sizing: border-box;
      height: 50px;
      width: 50px;
      background-size: 11px;
      background-color: rgb(255, 254, 252, 0.9);
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #d9d9d9;
      border-radius: 50%;
      cursor: pointer;
      outline: none;
      position: absolute;
      z-index: 5;
    }
    .swiperButton:hover {
      border: 2px solid rgba(0, 173, 102, 0.7);
    }

    .swiperPrev {
      left: 0;
      background-image: url('/prev-black.svg');
    }
    .swiperPrev:hover {
      background-image: url('/prev-green.svg');
    }

    .swiperNext {
      right: 0;
      background-image: url('/next-black.svg');
    }
    .swiperNext:hover {
      background-image: url('/next-green.svg');
    }
  }
}

.button {
  width: 100%;

  button {
    width: 100%;
    max-width: 263px;
    margin: 0 auto;
  }
}
