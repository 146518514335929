@import "../../scss/base.scss";

.title {
  margin: 0;
  font-family:
    Circular Std Bld,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: 400;
  color: $baseBlack;
}

.h1 {
  font-size: 35px;
  line-height: 44px;
  @include mobile {
    font-size: 25px;
    line-height: 32px;
  }
}

.h2 {
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
}

.h3 {
  font-size: 20px;
  line-height: 25px;
  @include mobile {
    font-size: 16px;
    line-height: 20px;
  }
}
