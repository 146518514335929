@import "../../scss/base.scss";

.play-button-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    > div.play-button {
      transition: all 0.3s;
      background-image: url("/video-player/play-hover.svg");
      background-position: center;
      background-size: contain;
    }

    > div.pause-button {
      transition: all 0.3s;
      background-image: url("/video-player/pause-hover.svg");
      background-position: center;
      background-size: contain;
    }
  }

  .play-button {
    background-image: url("/video-player/play.svg");
    transition: all 0.3s;
    background-position: center;
    background-size: contain;
  }

  .pause-button {
    background-image: url("/video-player/pause.svg");
    transition: all 0.3s;
    background-position: center;
    background-size: contain;
  }
}
