@import "../../scss/base.scss";

.card {
  width: 254px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @include mobile {
    width: 162px;
  }
  &.autosize {
    width: calc(50% - 10px);
    @include mobile {
      width: calc(50% - 7.5px);
    }
  }

  .imageContainer {
    width: 100%;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    a {
      .image {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px;
      }
    }

    .reserved {
      position: absolute;
      top: 0;
      left: 0;

      .rectangle {
        width: 83px;
        height: 85px;
        filter: drop-shadow(0px 2.58185px 5.16369px rgba(57, 57, 57, 0.25));
      }

      .text {
        position: absolute;
        font-size: 13px;
        color: #393939;
        font-family:
          Circular Std Book,
          Arial,
          Helvetica,
          sans-serif;
        font-weight: bold;
        transform: rotate(-45deg);
        top: 20px;
        left: -2px;
      }
    }

    .video {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: rgb(255, 255, 255, 0.9);
      position: absolute;
      top: 8px;
      left: 8px;

      .play {
        width: 18px;
        height: 18px;
        margin-left: 4px;
        background-image: url("/play.svg");
        &.isPlaying {
          background-image: url("/pause.svg");
          margin-left: 0px;
        }
      }
    }

    .icon {
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 18px;
        height: 18px;
      }
    }

    .favourite {
      box-sizing: border-box;
      width: 45px;
      height: 45px;
      padding-bottom: 6px;
      padding-left: 6px;
      border-bottom-left-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255, 0.9);

      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      @include mobile {
        width: 35px;
        height: 35px;
        padding-bottom: 4px;
      }

      .heart {
        width: 22px;
        height: 20px;
        background-image: url("/puppy/heart.svg");
        @include mobile {
          width: 17px;
          height: 15px;
        }

        &.isFavourite {
          background-image: url("/puppy/heart-favourite.svg");
        }
      }
      .heart:hover {
        background-image: url("/puppy/heart-hover.svg");
      }
    }

    .badge {
      width: fit-content;
      height: 24px;
      border-radius: 15px;
      padding: 0 12px;
      bottom: 12px;
      left: 8px;
      display: flex;
      align-items: center;
      background-color: white;
      position: absolute;
      @include mobile {
        bottom: 8px;
        left: 4px;
      }
    }
    // }
  }

  .content {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: $baseBlack;

    .truncate {
      display: -webkit-box;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      @include mobile {
        gap: 8px;
      }

      a {
        text-decoration: none;
      }

      .price {
        text-align: right;
        &.discount {
          color: #00ad66;
        }
      }

      .breed {
        display: flex;
        align-items: center;

        .gender {
          border-left: 1.5px solid $baseBlack;
          margin-left: 4px;
          padding-left: 4px;
          min-width: fit-content;
          line-height: 16px;
          @include mobile {
            border-left: 1px solid $baseBlack;
            line-height: 14px;
          }
        }
      }

      .originalPrice {
        text-decoration: line-through;
        text-align: right;
      }
    }
  }
}

.skeleton {
  height: 254px;
  min-width: 254px;
  @include mobile {
    min-width: 165px;
  }
  &.autosize {
    width: calc(50% - 10px);
    @include mobile {
      width: calc(50% - 7.5px);
    }
  }

  .image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;
      background-position: center;
    }
  }
  .content {
    margin-top: 10px;
    height: 105px;
    @include mobile {
      height: 80px;
      margin-top: 5px;
    }
  }
}
