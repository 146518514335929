@import '../../scss/base.scss';

.btn {
  padding: 10px 40px;
  border: none;
  border-radius: 80px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $mawoo-font-bold;
  font-size: 18px;

  @include mobile {
    padding: 10px 20px;
    font-size: 16px;
  }

  @include mobile {
    font-size: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.primary {
  background-color: $baseGreen;
  color: white;

  &:hover {
    background-color: darken($baseGreen, 5%);
  }

  &:disabled {
    background-color: $baseBlack;
    opacity: 0.4;
    color: white;
  }
}

.secondary {
  background-color: $baseBlack;
  color: white;

  &:hover {
    background-color: darken($baseBlack, 5%);
  }

  &:disabled {
    background-color: $baseBlack;
    opacity: 0.4;
    color: white;
  }
}

.tertiary {
  background-color: white;
  color: $baseBlack;

  &:hover {
    background-color: $baseBlack;
    color: white;
  }
}

.danger {
  background-color: $baseRedColor;
  color: white;

  &:hover {
    background-color: darken($baseRedColor, 5%);
  }
}

.outline {
  background-color: transparent;
  color: $baseGreen;
  border: 2px solid $baseGreen;

  &:hover {
    background-color: $baseGreen;
    color: white;
  }
}

.secondary-outline {
  background-color: transparent;
  color: $baseBlack;
  border: 2px solid $baseBlack;

  &:hover {
    background-color: $baseBlack;
    color: white;
  }
}
