@import '../../scss/base.scss';

.how {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: calc(1074px + 60px);
  width: 100%;
  padding: 0 30px;
  margin: 50px auto;

  @include mobile {
    padding: 0 15px;
    margin: 50px 0 40px;
  }

  h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    color: $baseBlack;
    font-family:
      Circular Std Bld,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 25px;
    font-weight: unset;
    line-height: 32px;
    text-align: center;

    @include mobile {
      margin-bottom: 12px;
      margin-top: 0;
    }
  }

  .steps {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    margin-bottom: 60px;

    @media (max-width: 770px) {
      flex-direction: column;
    }

    @include mobile {
      margin-bottom: 40px;
    }

    .step {
      box-sizing: border-box;
      width: 33.33%;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 770px) {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        padding: 30px 15px;
      }

      @include mobile {
        padding: 20px 10px;
      }

      .iconContainer {
        width: 95px;
        height: 80px;
        display: block;

        @media (max-width: 770px) {
          margin-right: 30px;
        }

        @include mobile {
          width: 70px;
          margin-right: 20px;
          height: 59px;
        }

        .icon {
          width: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .text {
        width: 100%;

        @media (max-width: 770px) {
          width: calc(100% - 90px - 30px);
        }

        @include mobile {
          width: calc(100% - 65px - 20px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: self-start;
        }

        p {
          margin: 0;
          font-family:
            Circular Std book,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 18px;
          font-weight: unset;
          line-height: 23px;
          color: #393939;
          text-align: center;

          a {
            color: $baseGreen;
            text-decoration: none;
            font-family: $mawoo-font-bold;
          }

          @media (max-width: 770px) {
            text-align: left;
          }

          @include mobile {
            font-size: 16px;
            line-height: 18px;
          }

          &.text__title {
            min-height: 64px;
            margin: 0;
            font-family:
              Circular Std Bld,
              Helvetica,
              Arial,
              sans-serif;
            font-size: 20px;
            line-height: 25px;
            color: #393939;
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            @media (max-width: 770px) {
              min-height: 0;
              margin-top: 0;
              text-align: left;
            }

            @include mobile {
              font-size: 18px;
              line-height: 23px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    > .step:nth-child(2) {
      border-width: 1px;
      border-color: rgba(57, 57, 57, 0.25);
      border-left-style: solid;
      border-right-style: solid;

      @media (max-width: 770px) {
        border-left-style: none;
        border-right-style: none;
        border-top-style: solid;
        border-bottom-style: solid;
      }
    }
  }

  .call {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      margin-top: 0;
    }

    h2 {
      margin: 0;
      font-family:
        Circular Std Bld,
        Helvetica,
        Arial,
        sans-serif;
      font-size: 25px;
      line-height: 32px;
      color: #393939;

      @include mobile {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .buttons {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @include mobile {
        display: flex;
        align-items: center;
        width: auto;
        gap: 0;
      }
    }

    &_date {
      margin: 0;
      padding-top: 10px;
      font-family: $mawoo-font-book;
      font-weight: unset;
      color: $baseBlack;
      font-size: 18px;

      @include mobile {
        font-size: 16px;
      }
    }
  }
}

/*
  Button Call 
*/

.btn {
  display: flex;

  &_icon {
    margin-right: 5px;
    stroke: $baseBlack;
  }
}

.btn:hover {
  .btn_icon {
    stroke: white;
  }
}
